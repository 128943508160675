const Error404 = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent:'center',
            flexDirection:'column',
            alignItems:'center', 
            height: '100vh',
        }}>
            <h1 style={{
                fontWeight: '600',
                fontSize: '150%'
            }}>
                404
            </h1>
            <p style={{
                textAlign: 'center'
            }}>
                Deze pagina is niet gevonden. Ga <a href="/">hier</a> terug naar de voorpagina.
            </p>    
        </div>
    )
}

export default Error404;