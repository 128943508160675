import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import React, { useState, useEffect } from 'react';
import LeafletGeocoder from "./LeafletGeocoder";
import 'bootstrap/dist/css/bootstrap.min.css';

delete L.Icon.Default.prototype._getIconUrl;

const formatter = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

const innerBounds = [
  [54.16996685441291, 0.6115172066989748],
  [49.77650081156617, 10.449878744193283],
]

export default function LeafletComponent() {
  const avg = require('average-array');
  const [ data, setData ] = useState(null);
  const [ userLat, setUserLat ] = useState(52.64214780658646);
  const [ userLng, setUserLng ] = useState(6.069636935113508);
  const tileLayerUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`;
  
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUserLat(position.coords.latitude);
      setUserLng(position.coords.longitude);
    });

    async function fetchData() {
      const response = await fetch('api/droppoints-data');
      const json = await response.json();

      setData(json);
    }
    fetchData();
  }, []);

  return (
    <>
      <MapContainer style={{ height: "100vh" }} center={[userLat, userLng]} zoom={9.25} scrollWheelZoom={true} minZoom={8}>
        <TileLayer
          attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
          url={tileLayerUrl}
          
        />
          {data && data.map((item, index) => 
          (
            <Marker key={index} icon={item.bikesAvailable > 0 ? item.bikeType === "pedalBike" ? AvailableRegular : AvailableEbike : item.bikeType === "pedalBike" ? UnavailableRegular : UnvailableEbike} position={[item.coordinates.latitude, item.coordinates.longitude]}>
              <Popup>
                <div>
                  <h3 style={{
                    textAlign: 'center',
                    fontWeight: '700'
                  }}>{item.name}</h3>
                  <div className="popup_flex">
                      <div className="popup_item">
                          <img src="/map_icons/popup-icon_gps.svg" alt="icon_gps" className="popup_icon" />
                          <div className="popup_item_text">
                              <div className="popup_item_title">Adres</div>
                              <div className="popup_item_desc">{item.address.streetAddress}</div>
                          </div>
                      </div>
                      <div className="popup_item">
                          <img src="/map_icons/popup-icon_checkmark.svg" alt="icon_checkmark" className="popup_icon" />
                          <div className="popup_item_text">
                              <div className="popup_item_title">Beschikbare fietsen</div>
                              <div className="popup_item_desc">{item.bikesAvailable}</div>
                          </div>
                      </div>
                      <div className="popup_item">
                          <img src="/map_icons/popup-icon_wheel.svg" alt="icon_wheel" className="popup_icon" />
                          <div className="popup_item_text">
                              <div className="popup_item_title">Type fiets</div>
                              <div className="popup_item_desc">{item.bikeType === "ebike" ? "Elektrisch" : "Regulier"}</div>
                          </div>
                      </div>
                      <div className="popup_item">
                          <img src="/map_icons/popup-icon_price.svg" alt="icon_price" className="popup_icon" />
                          <div className="popup_item_text">
                              <div className="popup_item_title">Prijs</div>
                              <div className="popup_item_desc">
                                {item.pricePlans.pricePer15m !== null || undefined  ? formatter.format(item.pricePlans.pricePer15m) : ""} per 15 minuten
                                <br />
                                {item.pricePlans.pricePerDay !== null || undefined ? formatter.format(item.pricePlans.pricePerDay) : ""} per dag
                                <br />
                                {item.pricePlans.startFee !== null ? formatter.format(item.pricePlans.startFee) + " starttarief" : ""} 
                              </div>
                          </div>
                      </div>
                </div>
              </div>
              </Popup>
            </Marker>
          ))}
      </MapContainer>
    </>);
}

const AvailableEbike = L.icon({
  iconUrl: '/map_icons/marker-available_ebike.png',
  iconSize: [84, 75]
});

const AvailableRegular = L.icon({
  iconUrl: '/map_icons/marker-available_regular.png',
  iconSize: [72, 75]
});

const UnvailableEbike = L.icon({
  iconUrl: '/map_icons/marker-unavailable_ebike.png',
  iconSize: [86, 75]
});

const UnavailableRegular = L.icon({
  iconUrl: '/map_icons/marker-unavailable_regular.png',
  iconSize: [72, 75]
});