import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './custom.css';
import Error404 from "./components/404";
import LeafletComponent from './components/LeafletMap';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <LeafletComponent /> } />
        <Route path="*" element={ <Error404 /> } />
      </Routes>
    </BrowserRouter>
  )
}

export default App;